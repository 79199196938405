import React from "react";
const Stats = ({ props }) => {
  return (
    <div className="text-3xl text-blue-500 mt-24 m-auto text-center">
      Stats Coming Soon!
    </div>
  );
};

export default Stats;
