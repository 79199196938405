import React from "react";
import { useEffect, useState } from "react";
import { useIdentityContext } from "react-netlify-identity";

const adminpage = ({ props }) => {
  const [province, updateProvince] = useState("");
  const [division, updateDivision] = useState("");
  const [district, updateDistrict] = useState("");
  const [tehsil, updateTehsil] = useState("");
  const [dealer, updateDealer] = useState("");

  const [Provinces, setProvinces] = useState([]);
  const [Divisions, setDivisions] = useState([]);
  const [Districts, setDistricts] = useState([]);
  const [Tehsils, setTehsils] = useState([]);
  const [Dealers, setDealers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useIdentityContext();

  async function requestProvinces() {
    setIsLoading(true);
    const res = await fetch(`//asim-ali.firebaseio.com/PAKDB/Provinces.json`);
    const json = await res.json();
    setProvinces(json);
    setIsLoading(false);
  }

  async function requestDivisions() {
    setIsLoading(true);
    const res = await fetch(
      `//asim-ali.firebaseio.com/PAKDB/Divisions.json?orderBy="PROVINCE"&equalTo="${province}"`
    );
    const json = await res.json();
    const json2arr = Object.keys(json).map((key) => json[key]);
    setDivisions(json2arr);
    setIsLoading(false);
  }

  async function requestDistricts() {
    setIsLoading(true);
    const res = await fetch(
      `//asim-ali.firebaseio.com/PAKDB/Districts.json?orderBy="DIVISION"&equalTo="${division}"`
    );
    const json = await res.json();
    const json2arr = Object.keys(json).map((key) => json[key]);
    setDistricts(json2arr);
    setIsLoading(false);
  }

  async function requestTehsils() {
    setIsLoading(true);
    const res = await fetch(
      `//asim-ali.firebaseio.com/PAKDB/Tehsils.json?orderBy="DISTRICT"&equalTo="${district}"`
    );
    const json = await res.json();
    const json2arr = Object.keys(json).map((key) => json[key]);
    setTehsils(json2arr);
    setIsLoading(false);
  }

  async function requestDealers() {
    setIsLoading(true);
    const dealers = await fetch(`/.netlify/functions/checkloc?field=DEALER`);
    const json = await dealers.json();
    setDealers(json);
    setIsLoading(false);
  }

  function handleProvincechange(e) {
    updateProvince(e.target.value);
    updateDivision("Loading");
    updateDistrict("Loading");
    updateTehsil("Loading");
    document.querySelector(".message").innerText = "";
  }

  function handleDivisionchange(e) {
    updateDivision(e.target.value);
    updateDistrict("Loading");
    updateTehsil("Loading");
  }

  function handleDistrictchange(e) {
    updateDistrict(e.target.value);
    updateTehsil("Loading");
  }

  function handleTehsilchange(e) {
    updateTehsil(e.target.value);
  }

  function handleDealerchange(e) {
    updateDealer(e.target.value);
    document.querySelector(".message").innerText = "";
  }

  useEffect(() => {
    requestTehsils();
  }, [district]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestDistricts();
  }, [division]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestDivisions();
  }, [province]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestProvinces();
    requestDealers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmit(event) {
    const data = new FormData(event.target);

    const result = await fetch("/.netlify/functions/addstore", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token.access_token}`,
      },
      body: JSON.stringify({
        store_name: data.get("store_name"),
        dealer: dealer,
        province: province,
        division: division,
        district: district,
        tehsil: tehsil,
        sale_target: data.get("sale_target"),
        sale_current: data.get("sale_current"),
        contact_person: data.get("contact_person"),
        contact_phone: data.get("contact_phone"),
        supplier_names: data.get("supplier_names"),
        areas_alloted: data.get("areas_alloted"),
      }),
    })
      .then((response) => {
        document.querySelector(".message").innerText = `Status: ${
          response.status === 201 ? "Success" : "Failed"
        } - Code : ${response.status}`;
      })
      .then(event.target.reset());
  }

  return (
    <div className="Admin">
      <div
        className="flex justify-center items-center w-full px-2"
        style={{ background: "#0073cf" }}
      >
        <div className="w-full bg-white rounded shadow-2xl p-8 m-4">
          <h1 className="block w-full text-center text-gray-800 text-2xl font-bold mb-6">
            Add New Store
          </h1>
          <div className="message block w-full text-center text-gray-800 text-l font-bold mb-6"></div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="store_name"
              >
                Store Name
              </label>
              <input
                className="border py-2 px-3 text-grey-800"
                type="text"
                name="store_name"
                id="store_name"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="dealer"
              >
                Dealer Name
              </label>
              <select
                id="dealer"
                value={dealer}
                onChange={handleDealerchange}
                onBlur={handleDealerchange}
                className="border py-2 px-3 text-grey-800"
                required="required"
              >
                <option>Select Dealer</option>
                {Dealers.map((deal) => (
                  <option key={deal} value={deal}>
                    {deal}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="province"
              >
                Province Name
              </label>
              <select
                id="province"
                value={province}
                onChange={handleProvincechange}
                onBlur={handleProvincechange}
                className="border py-2 px-3 text-grey-800"
                required="required"
              >
                <option>Select Province</option>
                {Provinces.map((prov) => (
                  <option key={prov.PROVINCE} value={prov.PROVINCE}>
                    {prov.PROVINCE}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="division"
              >
                Division Name
              </label>
              <select
                disabled={isLoading}
                id="division"
                value={division}
                onChange={handleDivisionchange}
                onBlur={handleDivisionchange}
                className="border py-2 px-3 text-grey-800"
                required="required"
              >
                <option>Select Division</option>
                {Divisions.map((divi) => (
                  <option key={divi.DIVISION} value={divi.DIVISION}>
                    {divi.DIVISION}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="district"
              >
                District Name
              </label>
              <select
                disabled={isLoading}
                id="district"
                value={district}
                onChange={handleDistrictchange}
                onBlur={handleDistrictchange}
                className="border py-2 px-3 text-grey-800"
                required="required"
              >
                <option>Select District</option>
                {Districts.map((dist) => (
                  <option key={dist.DISTRICT} value={dist.DISTRICT}>
                    {dist.DISTRICT}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="tehsil"
              >
                Tehsil Name
              </label>
              <select
                disabled={isLoading}
                id="tehsil"
                value={tehsil}
                onChange={handleTehsilchange}
                onBlur={handleTehsilchange}
                className="border py-2 px-3 text-grey-800"
                required="required"
              >
                <option>Select Tehsil</option>
                {Tehsils.map((teh) => (
                  <option key={teh.TEHSIL} value={teh.TEHSIL}>
                    {teh.TEHSIL}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="sale_target"
              >
                Sale Target
              </label>
              <input
                className="border py-2 px-3 text-grey-800"
                type="number"
                name="sale_target"
                id="sale_target"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="sale_current"
              >
                Sale Current
              </label>
              <input
                className="border py-2 px-3 text-grey-800"
                type="number"
                name="sale_current"
                id="sale_current"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="contact_person"
              >
                Contact Person
              </label>
              <input
                className="border py-2 px-3 text-grey-800"
                type="text"
                name="contact_person"
                id="contact_person"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="contact_phone"
              >
                Contact Phone
              </label>
              <input
                className="border py-2 px-3 text-grey-800"
                type="tel"
                name="contact_phone"
                id="contact_phone"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="supplier_names"
              >
                Supplier
              </label>
              <input
                className="border py-2 px-3 text-grey-800"
                type="text"
                name="supplier_names"
                id="supplier_names"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label
                className="mb-2 font-bold text-lg text-gray-900"
                htmlFor="areas_alloted"
              >
                Areas Alloted
              </label>
              <input
                className="border py-2 px-3 text-grey-800"
                type="text"
                name="areas_alloted"
                id="areas_alloted"
              />
            </div>
            <button
              className="btn w-full font-medium text-white uppercase text-lg mx-auto py-2 px-4 rounded"
              style={{ background: "#ad343e" }}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default adminpage;
