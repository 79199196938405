import React from "react";
import markerIcon from "url:../images/marker.svg";
import markerIconagi from "url:../images/marker-agi.svg";
import markerIconwag from "url:../images/marker-wag.svg";
import markerIconmmm from "url:../images/marker-mmm.svg";
import markerIconalf from "url:../images/marker-alf.svg";

const Marker = (props) => {
  const dealerName = props.dealer;

  const style = {
    width: "2rem",
    height: "2rem",
    marginTop: "-2rem",
    marginLeft: "-1rem",
    // transform: "translate(-50%, -50%)",
    backgroundImage: `url(${markerIcon})`,
  };

  switch (dealerName) {
    case "AGI":
      style.backgroundImage = `url(${markerIconagi})`;
      break;
    case "WAG":
      style.backgroundImage = `url(${markerIconwag})`;
      break;
    case "MMM":
      style.backgroundImage = `url(${markerIconmmm})`;
      break;
    case "ALFALAH":
      style.backgroundImage = `url(${markerIconalf})`;
      break;
    case "EMP":
      style.backgroundImage = `url(${markerIcon})`;
      break;
  }

  return <div key={props.key} style={style} />;
};

export default Marker;
