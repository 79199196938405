import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Results from "./Results";
import Maps from "./Maps";
import Modal from "./Modal";
import { useIdentityContext } from "react-netlify-identity";
import Select from "react-select";
import Split from "react-split";
import { list } from "postcss";

const Dealers = () => {
  const { dealerName } = useParams();
  const { user } = useIdentityContext();

  const [province, updateProvince] = useState("");
  const [division, updateDivision] = useState("");
  const [district, updateDistrict] = useState("");

  const [Provinces, setProvinces] = useState([]);
  const [Divisions, setDivisions] = useState([]);
  const [Districts, setDistricts] = useState([]);

  const [Stores, setStores] = useState([]);

  const [MapList, setMapList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [districtCovered, setDistrictCovered] = useState([]);
  const [divisonCovered, setDivisionCovered] = useState([]);
  const [provinceCovered, setProvinceCovered] = useState([]);

  const [listitem, setListitem] = useState("");
  const [currentStore, updateCurrentStore] = useState();

  const [showTargetModal, setShowTargetModal] = useState(false);

  const [defaulttarget, setDefaulttarget] = useState("");
  const [defaultcurrent, setDefaultcurrent] = useState("");

  useEffect(() => {
    updateProvince(() => (province == "" ? listitem : province));
    updateDivision(() =>
      listitem.split(" ").pop() == "Division" ? listitem : division
    );
    updateDistrict(() =>
      listitem.split(" ").pop() == "Tehsil" ? listitem : district
    );
  }, [listitem]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestStores();
    // setMapList(Districts);
  }, [district]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestDistricts();
    // setMapList(Divisions);
  }, [division]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestDivisions();
    // setMapList(Divisions);
  }, [province]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestProvinces();
    checkLocationExists();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentStore != undefined ? toggleTargetModal() : null;
  }, [currentStore]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    showTargetModal ? null : updateCurrentStore();
  }, [showTargetModal]); // eslint-disable-line react-hooks/exhaustive-deps

  async function checkLocationExists() {
    setIsLoading(true);
    const provinceres = await fetch(
      `/.netlify/functions/dealercheckloc?field=PROVINCE&dealer=${dealerName}`
    );
    const divisionres = await fetch(
      `/.netlify/functions/dealercheckloc?field=DIVISION&dealer=${dealerName}`
    );
    const districtres = await fetch(
      `/.netlify/functions/dealercheckloc?field=TEHSIL&dealer=${dealerName}`
    );
    const provincejson = await provinceres.json();
    const divisionjson = await divisionres.json();
    const districtjson = await districtres.json();
    setProvinceCovered(provincejson);
    setDivisionCovered(divisionjson);
    setDistrictCovered(districtjson);
    setIsLoading(false);
  }

  async function requestProvinces() {
    setIsLoading(true);
    const res = await fetch(`//asim-ali.firebaseio.com/PAKDB/Provinces.json`);
    const json = await res.json();
    setProvinces(json);
    setMapList(json);
    setStores([]);
    setIsLoading(false);
  }

  async function requestDivisions() {
    setIsLoading(true);
    const res = await fetch(
      `//asim-ali.firebaseio.com/PAKDB/Divisions.json?orderBy="PROVINCE"&equalTo="${listitem}"`
    );
    const json = await res.json();
    const json2arr = Object.keys(json).map((key) => json[key]);
    const result = [];
    json2arr.map((divi) => {
      !divisonCovered.includes(divi.DIVISION) ? null : result.push(divi);
    });
    setDivisions(json2arr);
    setMapList(result);
    setStores([]);
    setIsLoading(false);
  }

  async function requestDistricts() {
    setIsLoading(true);
    const res = await fetch(
      `//asim-ali.firebaseio.com/PAKDB/Tehsils.json?orderBy="DIVISION"&equalTo="${listitem}"`
    );
    const json = await res.json();
    const json2arr = Object.keys(json).map((key) => json[key]);
    const result = [];
    // json2arr.map((dist) => {
    //   !districtCovered.includes(dist.DISTRICT) ? null : result.push(dist);
    // });
    setDistricts(json2arr);
    // setMapList(result);
    setMapList(json2arr);
    setStores([]);
    setIsLoading(false);
  }

  async function requestStores() {
    setIsLoading(true);
    const res = await fetch(
      `/.netlify/functions/dealerstorefind?field=TEHSIL&value=${district}&dealer=${dealerName}`
    );
    const json = await res.json();
    setStores(json);
    setIsLoading(false);
  }

  function handleProvincechange(e) {
    if (e.target.value != "Province") {
      updateProvince(e.target.value);
      setListitem(e.target.value);
      updateDivision("");
    }
  }

  function handleDivisionchange(e) {
    if (e.target.value != "Division") {
      updateDivision(e.target.value);
      setListitem(e.target.value);
      updateDistrict("");
    }
  }

  function handleDistrictchange(e) {
    if (e.target.value != "City") {
      updateDistrict(e.target.value);
    }
  }

  const toggleTargetModal = (e) => {
    setShowTargetModal(!showTargetModal);
    setDefaulttarget(
      Stores.find(({ NAME }) => NAME === currentStore)?.SALEVOLUME
    );
    setDefaultcurrent(
      Stores.find(({ NAME }) => NAME === currentStore)?.SALECURRENT
    );
  };

  async function handleSubmit(event) {
    const data = new FormData(event.target);

    const result = await fetch("/.netlify/functions/updatetarget", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token.access_token}`,
      },
      body: JSON.stringify({
        store_name: currentStore,
        sale_target: data.get("sale_target"),
        sale_current: data.get("sale_current"),
      }),
    })
      .then((response) => {
        document.querySelector(".message").innerText = `Status: ${
          response.status === 201 ? "Success" : "Failed"
        }`;
      })
      .then(event.target.reset())
      .then(
        setTimeout(() => {
          requestStores();
        }, 1000)
      );
  }

  // console.log(
  //   "Clicked :",
  //   listitem,
  //   ", Province :",
  //   province,
  //   ", Division :",
  //   division,
  //   ", District :",
  //   district
  // );

  // console.log(districtCovered, divisonCovered, provinceCovered);
  // console.log(currentStore);

  return (
    <div>
      <Split
        className="pt-2 split h-screen"
        direction="vertical"
        style={{ background: "#faeff0" }}
      >
        <div className="overflow-y-scroll">
          <div
            className="flex px-2 mx-2 mt-1 justify-between mb-4 row content"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   requestPets();
            // }}
          >
            {/* <label htmlFor="province" className="block text-left"> */}
            <select
              id="province"
              value={province}
              onChange={handleProvincechange}
              onBlur={handleProvincechange}
              className="form-select block w-full mr-2 border rounded-sm text-gray-700 focus:outline-none focus:border-green-500"
            >
              <option>Province</option>
              {Provinces.map((province) => (
                <option key={province.PROVINCE} value={province.PROVINCE}>
                  {province.PROVINCE}
                </option>
              ))}
            </select>
            <select
              disabled={!province}
              id="division"
              value={division}
              onChange={handleDivisionchange}
              onBlur={handleDivisionchange}
              className="form-select block w-full mr-2 border rounded-sm text-gray-700 focus:outline-none focus:border-green-500"
            >
              <option>Division</option>
              {Divisions.map((division) => (
                <option key={division.DIVISION} value={division.DIVISION}>
                  {division.DIVISION}
                </option>
              ))}
            </select>
            <select
              disabled={!division}
              id="district"
              value={district}
              onChange={handleDistrictchange}
              onBlur={handleDistrictchange}
              className="form-select block w-full border rounded-sm text-gray-700 focus:outline-none focus:border-green-500"
            >
              <option>City</option>
              {Districts.map((district) => (
                <option key={district.TEHSIL} value={district.TEHSIL}>
                  {district.TEHSIL}
                </option>
              ))}
            </select>
            {/* </label> */}
            {/* <button>Submit</button> */}
          </div>
          <div>
            {isLoading ? (
              <p className="text-center my-5 text-xl">Loading ...</p>
            ) : (
              <Results
                key={listitem}
                provinces={Provinces}
                divisions={Divisions}
                districts={Districts}
                provinceCovered={provinceCovered}
                divisonCovered={divisonCovered}
                districtCovered={districtCovered}
                stores={Stores}
                onChildClick={setListitem}
                onEditClick={updateCurrentStore}
                dealer={""}
              />
            )}
          </div>
        </div>
        <div className="overflow-y-scroll">
          <Maps
            dealer={dealerName}
            mapdata={MapList}
            province={province}
            division={division}
          />
        </div>
      </Split>
      {showTargetModal ? (
        <Modal>
          <div>
            <h2 className="mb-2 font-bold text-xl text-gray-900">
              {currentStore}
            </h2>
            <div className="message block w-full text-center text-gray-800 text-l font-bold mb-6"></div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <div className="flex flex-col mb-4">
                <label
                  className="mb-2 font-semibold text-lg text-gray-900"
                  htmlFor="sale_target"
                >
                  Sale Target
                </label>
                <input
                  className="border py-2 px-3 text-grey-800"
                  type="number"
                  name="sale_target"
                  value={defaulttarget}
                  onChange={(e) => setDefaulttarget(e.target.value)}
                  id="sale_target"
                  required
                />
              </div>
              <div className="flex flex-col mb-4">
                <label
                  className="mb-2 font-semibold text-lg text-gray-900"
                  htmlFor="sale_current"
                >
                  Current Sale
                </label>
                <input
                  className="border py-2 px-3 text-grey-800"
                  type="number"
                  name="sale_current"
                  value={defaultcurrent}
                  onChange={(e) => setDefaultcurrent(e.target.value)}
                  id="sale_current"
                  required
                />
              </div>
              <div className="buttons">
                <button type="submit">Submit</button>
                <button onClick={toggleTargetModal}>Close</button>
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Dealers;
