import { Link } from "react-router-dom";
import React from "react";

const DealersParams = () => {
  return (
    <div className="mt-5 ">
      <h1 className="text-2xl text-center uppercase">AGI Main Distributors</h1>

      <div className="my-3 divide-y divide-gray-100 bg-white border-t-2">
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/MMM"
        >
          1. Multan Motors Multan
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/ALFALAH"
        >
          2. Al-falah Screen
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/SARDAR"
        >
          3. Sardar Screen
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/WAG"
        >
          4. Waqas Auto Glasses
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/ADEEL"
        >
          5. Adeel Auto
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/SALEEM"
        >
          6. Saleem Auto
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/JAN"
        >
          7. Jan Auto
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/MUAAZ"
        >
          8. Muaaz Auto
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/SAEED"
        >
          9. Saeed Auto
        </Link>
        <Link
          className="block p-3 pl-5 py-5 hover:bg-blue-600 hover:text-blue-200 shadow text-xl"
          to="/dealer/HASEEB"
        >
          10. Haseeb Auto
        </Link>
      </div>
    </div>
  );
};

export default DealersParams;
