import { render } from "react-dom";
import React from "react";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Link,
} from "react-router-dom";
import SearchParams from "./SearchParams";
import Modal from "./Modal";
import DealerParams from "./DealersParams";
import AdminPage from "./Adminpage";
import VacantAreas from "./VacantAreas";
import Stats from "./Stats";
import * as serviceWorker from "./serviceWorker";
import logo from "../images/logo-t.png";
import Dealers from "./Dealers";
import goback from "url:../images/back-arrow.svg";
import {
  IdentityContextProvider,
  useIdentityContext,
} from "react-netlify-identity";
import "react-netlify-identity-widget/styles.css";

const App = () => {
  const [showModal, setShowModal] = useState(false);
  const url = "https://network.ahmadglass.com/";

  const toggleModal = (e) => {
    setShowModal(!showModal);
  };

  const redirect = () => (window.location = "https://www.ahmadglass.com");

  const PublicRoute = (props) => {
    const { isLoggedIn } = useIdentityContext();
    return isLoggedIn ? <Route {...props} /> : <Route {...props} />;
  };

  const PrivateRoute = (props) => {
    const { isLoggedIn, isConfirmedUser, logoutUser, user } =
      useIdentityContext();
    return isLoggedIn &&
      isConfirmedUser &&
      user.app_metadata.roles?.includes("admin") ? (
      <Route {...props} />
    ) : (
      logoutUser && <Redirect to="/" />
    );
  };

  const IdentityModal = React.lazy(() =>
    import("react-netlify-identity-widget")
  );

  function LoginModal() {
    const identity = useIdentityContext();
    const [dialog, setDialog] = useState(false);
    const isLoggedIn = identity && identity.isLoggedIn;
    return (
      <div className="App px-2 my-4">
        <button
          className="btn w-full text-white font-medium py-2 px-4 rounded"
          style={{ background: "#ad343e" }}
          onClick={() => setDialog(true)}
        >
          {isLoggedIn ? "LOG OUT" : "LOG IN"}
        </button>
        <React.Suspense fallback="loading...">
          <IdentityModal
            showDialog={dialog}
            onCloseDialog={() => setDialog(false)}
          />
        </React.Suspense>
      </div>
    );
  }

  return (
    <IdentityContextProvider url={url}>
      <div className="p-0 m-0 box-border">
        <Router>
          <header
            className="px-2 flex text-center justify-between"
            style={{ background: "#ad343e" }}
          >
            <Link className="text-4xl text-white hover:text-gray-200" to="/">
              AGI NETWORK
            </Link>
            <span className="">
              <button
                className="text-1xl text-white hover:text-gray-200 mr-1 p-2"
                onClick={toggleModal}
              >
                About
              </button>
              <Link
                className="p-4 py-1 bg-no-repeat"
                style={{ backgroundImage: `url(${goback})` }}
                to="/"
              ></Link>
            </span>
          </header>
          <Switch>
            <PrivateRoute path="/admin/">
              <AdminPage />
            </PrivateRoute>
            <PrivateRoute path="/dealer/:dealerName">
              <Dealers />
            </PrivateRoute>
            <PrivateRoute path="/dealers/">
              <DealerParams />
            </PrivateRoute>
            <PrivateRoute path="/areas/">
              <SearchParams />
            </PrivateRoute>
            <PrivateRoute path="/vacant-areas/">
              <VacantAreas />
            </PrivateRoute>
            <PublicRoute path="/stats/">
              <Stats />
            </PublicRoute>
            <PublicRoute path="/">
              <div>
                <img
                  className="block my-4 mx-auto"
                  src={logo}
                  alt="AGI LOGO"
                  width="275"
                  height="138"
                />
              </div>
              <div className="menu-block">
                <Link className="menu-item" to="/dealers/">
                  Dealers
                </Link>
                <Link className="menu-item" to="/dealer/AGI">
                  AGI Dealers
                </Link>
                <Link className="menu-item" to="/areas/">
                  AGI Areas
                </Link>
                <Link className="menu-item" to="/vacant-areas/">
                  Vacant Areas
                </Link>
                <Link className="menu-item" to="/stats/">
                  Coverage Stats
                </Link>
                <Link className="menu-item" to="/admin/">
                  Add Store
                </Link>
              </div>
              <LoginModal />
            </PublicRoute>
          </Switch>
        </Router>
        {showModal ? (
          <Modal>
            <div>
              <img
                className="block m-auto"
                src={logo}
                alt="AGI LOGO"
                width="275"
                height="138"
              />
              <br />
              <h1>
                AGI is the leading autoglass provider in Pakistan. The website
                provides information about the network of AGI and how vast it
                is. There are multiple branches in almost every city and region
                of Pakistan.
              </h1>
              <div className="buttons">
                <button onClick={redirect}>Official Website</button>
                <button onClick={toggleModal}>Close</button>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    </IdentityContextProvider>
  );
};

render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
