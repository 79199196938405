import React from "react";
import { useEffect, useState } from "react";
import ListItem from "./ListItem";

const Results = ({
  provinces,
  divisions,
  districts,
  provinceCovered,
  divisonCovered,
  districtCovered,
  dealer,
  stores,
  onChildClick,
  onEditClick,
}) => {
  let presence;

  // console.log(locations);
  // console.log(stores);
  // console.log(!stores.contains(stores));
  // const dstores = stores.stores || [];

  // useEffect(() => {
  //   // requestprovinces();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (stores != undefined && stores.length !== 0) {
    // console.log(vacantcities);
    // console.log(stores);
    return (
      <div className="">
        {!stores.length ? (
          <h1 className="text-center my-5 text-xl">No Stores Found</h1>
        ) : (
          stores.map((store, index) => {
            return (
              <ListItem
                key={index}
                id={index}
                dealerName={store.DEALER}
                current={store.NAME}
                division={store.DIVISION}
                currentSALE={store.SALECURRENT}
                targetSALE={store.SALEVOLUME}
                presence={true}
                onChildClick={onChildClick}
                onEditClick={onEditClick}
                last={true}
              />
            );
          })
        )}
      </div>
    );
  } else if (districts != undefined && districts.length !== 0) {
    return (
      <div className="">
        {!districts.length ? (
          <h1 className="text-center my-5 text-xl">No Districts Found</h1>
        ) : (
          districts.map((district, index) => {
            {
              districtCovered.includes(district.DISTRICT)
                ? (presence = false)
                : (presence = false);
            }
            return (
              <ListItem
                key={index}
                id={index}
                dealerName={dealer}
                current={district.TEHSIL}
                population={district.POP}
                division={district.DIVISION}
                district={district.DISTRICT}
                presence={presence}
                onChildClick={onChildClick}
              />
            );
          })
        )}
      </div>
    );
  } else if (divisions != undefined && divisions.length !== 0) {
    return (
      <div className="">
        {!divisions.length ? (
          <h1 className="text-center my-5 text-xl">No Divisions Found</h1>
        ) : (
          divisions.map((division, index) => {
            {
              divisonCovered.includes(division.DIVISION)
                ? (presence = true)
                : (presence = false);
            }
            return (
              <ListItem
                key={index}
                id={index}
                dealerName={dealer}
                current={division.DIVISION}
                population={division.POP}
                division={division.DIVISION}
                presence={presence}
                onChildClick={onChildClick}
              />
            );
          })
        )}
      </div>
    );
  } /*if (provinces != undefined && provinces.length !== 0)*/ else {
    return (
      <div className="">
        {!provinces.length ? (
          <h1 className="text-center my-5 text-xl">No Provinces Found</h1>
        ) : (
          provinces.map((province, index) => {
            {
              provinceCovered.includes(province.PROVINCE)
                ? (presence = true)
                : (presence = false);
            }
            return (
              <ListItem
                key={index}
                id={index}
                dealerName={dealer}
                current={province.PROVINCE}
                population={province.POP}
                province={province.PROVINCE}
                presence={presence}
                onChildClick={onChildClick}
              />
            );
          })
        )}
      </div>
    );
  } /*else {
    vacantcities != undefined || locations != []
      ? (locations = locations.concat(vacantcities))
      : null;
    return (
      <div className="">
        {!locations.length ? (
          <h1 className="text-center my-5 text-xl">No Locations Found</h1>
        ) : (
          locations.map((location, index) => {
            !location[1].stores ? (dealer = "Vacant City") : null;
            return (
              <ListItem
                key={index}
                id={location.id}
                dealerName={dealer}
                current={location[0]}
                division={location.breed}
                stores={location[1].stores || []}
              />
            );
          })
        )}
      </div>
    );
  }*/
};

export default Results;
