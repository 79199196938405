import React from "react";
const ListItem = (props) => {
  const {
    current,
    dealerName,
    population,
    presence,
    targetSALE,
    currentSALE,
    onChildClick,
    onEditClick,
  } = props;
  let bgcolor = "bg-green-200";
  !presence ? (bgcolor = "bg-red-200") : null;

  if (!props.last) {
    function trigger() {
      onChildClick(current);
    }
  }

  return (
    <div onClick={trigger} className={`flex p-2 mx-2 mb-1 border-2 ${bgcolor}`}>
      <div className="w-full">
        <div>{current}</div>
        {props.last ? (
          <div className="flex">
            <div className="w-3/12">{dealerName}</div>
            <div className="flex w-8/12">
              <div className="pl-1 pr-1">Target: {targetSALE} </div>
              <div className="pl-1">Current: {currentSALE}</div>
            </div>
            <div className="w-1/12 text-right">
              <button className="" onClick={(e) => onEditClick(current)}>
                Edit
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <div className="">
        <div>{population}</div>
      </div>
    </div>
  );
};

export default ListItem;
