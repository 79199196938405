import React from "react";
import { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

const defaultProps = {
  center: {
    lat: 28,
    lng: 69,
  },
  zoom: 5,
};

const Maps = (props) => {
  const [mapdata, setMapdata] = useState([]);
  const [province, setProvince] = useState();
  const [division, setDivision] = useState();

  const [dealerName, setdealerName] = useState("");

  const [divisions, setDivisions] = useState([]);
  const [districts, setDistricts] = useState([]);

  if (mapdata !== undefined && mapdata.length !== 0) {
    let currentprovince, currentdivision;
    if (province !== undefined && province !== "") {
      currentprovince = mapdata.find(() => province);
      defaultProps.center = {
        lat: currentprovince.LAT,
        lng: currentprovince.LNG,
      };
      defaultProps.zoom = 6;
    }

    if (division !== undefined && division !== "") {
      currentdivision = mapdata.find(() => division);
      defaultProps.center = {
        lat: currentdivision.LAT,
        lng: currentdivision.LNG,
      };
      defaultProps.zoom = 7;
    }
  }

  useEffect(() => {
    setMapdata(props.mapdata);
    setProvince(props.province);
    setDivision(props.division);
    setdealerName(props.dealer);
    defaultProps.zoom = 5;
  }, [props]);

  // console.log(mapdata);

  return (
    <div style={{ height: "90%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.mapapi }}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        key={defaultProps.center}
      >
        {mapdata.map((current, index) => (
          <Marker
            key={index}
            lat={current.LAT}
            lng={current.LNG}
            // dealer={
            //   key[1].stores !== undefined
            //     ? key[1].stores[0] !== undefined
            //       ? key[1].stores[0].dealer
            //       : null
            //     : null
            // }
            dealer={dealerName}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Maps;
