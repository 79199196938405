import React from "react";
import { useEffect, useState } from "react";
import Results from "./Results";
import Maps from "./Maps";
import Select from "react-select";
import Split from "react-split";
import { list } from "postcss";

const VacantAreas = () => {
  const [province, updateProvince] = useState("");
  const [division, updateDivision] = useState("");
  const [district, updateDistrict] = useState("");

  const [Provinces, setProvinces] = useState([]);
  const [Divisions, setDivisions] = useState([]);
  const [Districts, setDistricts] = useState([]);

  const [Stores, setStores] = useState([]);

  const [MapList, setMapList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [districtCovered, setDistrictCovered] = useState([]);
  const [divisonCovered, setDivisionCovered] = useState([]);
  const [provinceCovered, setProvinceCovered] = useState([]);

  const [listitem, setListitem] = useState("");

  useEffect(() => {
    updateProvince(() => (province == "" ? listitem : province));
    updateDivision(() =>
      listitem.split(" ").pop() == "Division" ? listitem : division
    );
    updateDistrict(() =>
      listitem.split(" ").pop() == "Tehsil" ? listitem : district
    );
  }, [listitem]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestStores();
    // setMapList(Districts);
  }, [district]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestDistricts();
    // setMapList(Divisions);
  }, [division]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestDivisions();
    // setMapList(Divisions);
  }, [province]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestProvinces();
    checkLocationExists();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function checkLocationExists() {
    setIsLoading(true);
    const provinceres = await fetch(
      `/.netlify/functions/checkloc?field=PROVINCE`
    );
    const divisionres = await fetch(
      `/.netlify/functions/checkloc?field=DIVISION`
    );
    const districtres = await fetch(
      `/.netlify/functions/checkloc?field=TEHSIL`
    );
    const provincejson = await provinceres.json();
    const divisionjson = await divisionres.json();
    const districtjson = await districtres.json();
    setProvinceCovered(provincejson);
    setDivisionCovered(divisionjson);
    setDistrictCovered(districtjson);
    setIsLoading(false);
  }

  async function requestProvinces() {
    setIsLoading(true);
    const res = await fetch(`//asim-ali.firebaseio.com/PAKDB/Provinces.json`);
    const json = await res.json();
    setProvinces(json);
    setMapList(json);
    setStores([]);
    setIsLoading(false);
  }

  async function requestDivisions() {
    setIsLoading(true);
    const res = await fetch(
      `//asim-ali.firebaseio.com/PAKDB/Divisions.json?orderBy="PROVINCE"&equalTo="${listitem}"`
    );
    const json = await res.json();
    const json2arr = Object.keys(json).map((key) => json[key]);
    setDivisions(json2arr);
    setMapList(json2arr);
    setStores([]);
    setIsLoading(false);
  }

  async function requestDistricts() {
    setIsLoading(true);
    const res = await fetch(
      `//asim-ali.firebaseio.com/PAKDB/Tehsils.json?orderBy="DIVISION"&equalTo="${listitem}"`
    );
    const json = await res.json();
    const json2arr = Object.keys(json).map((key) => json[key]);
    const result = [];
    // json2arr.map((dist) => {
    //   !districtCovered.includes(dist.DISTRICT) ? result.push(dist) : null;
    // });
    // setDistricts(result);
    setDistricts(json2arr);
    // setMapList(result);
    setMapList(json2arr);
    setStores([]);
    setIsLoading(false);
  }

  async function requestStores() {
    setIsLoading(true);
    const res = await fetch(
      `/.netlify/functions/storefind?field=TEHSIL&value=${district}`
    );
    const json = await res.json();
    setStores(json);
    setIsLoading(false);
  }

  function handleProvincechange(e) {
    if (e.target.value != "Province") {
      updateProvince(e.target.value);
      setListitem(e.target.value);
      updateDivision("");
    }
  }

  function handleDivisionchange(e) {
    if (e.target.value != "Division") {
      updateDivision(e.target.value);
      setListitem(e.target.value);
    }
  }

  function handleDistrictchange(e) {
    if (e.target.value != "City") {
      updateDistrict(e.target.value);
    }
  }

  // console.log(
  //   "Clicked :",
  //   listitem,
  //   ", Province :",
  //   province,
  //   ", Division :",
  //   division,
  //   ", District :",
  //   district
  // );

  // console.log(districtCovered, divisonCovered, provinceCovered);

  return (
    <div>
      <Split
        className="pt-2 split h-screen"
        direction="vertical"
        style={{ background: "#faeff0" }}
      >
        <div className="overflow-y-scroll">
          <div
            className="flex px-2 mx-2 mt-1 justify-between mb-4 row content"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   requestPets();
            // }}
          >
            {/* <label htmlFor="province" className="block text-left"> */}
            <select
              id="province"
              value={province}
              onChange={handleProvincechange}
              onBlur={handleProvincechange}
              className="form-select block w-full mr-2 border rounded-sm text-gray-700 focus:outline-none focus:border-green-500"
            >
              <option>Province</option>
              {Provinces.map((province) => (
                <option key={province.PROVINCE} value={province.PROVINCE}>
                  {province.PROVINCE}
                </option>
              ))}
            </select>
            <select
              disabled={!province}
              id="division"
              value={division}
              onChange={handleDivisionchange}
              onBlur={handleDivisionchange}
              className="form-select block w-full mr-2 border rounded-sm text-gray-700 focus:outline-none focus:border-green-500"
            >
              <option>Division</option>
              {Divisions.map((division) => (
                <option key={division.DIVISION} value={division.DIVISION}>
                  {division.DIVISION}
                </option>
              ))}
            </select>
            <select
              disabled={!division}
              id="district"
              value={district}
              onChange={handleDistrictchange}
              onBlur={handleDistrictchange}
              className="form-select block w-full border rounded-sm text-gray-700 focus:outline-none focus:border-green-500"
            >
              <option>City</option>
              {Districts.map((district) => (
                <option key={district.TEHSIL} value={district.TEHSIL}>
                  {district.TEHSIL}
                </option>
              ))}
            </select>
            {/* </label> */}
            {/* <button>Submit</button> */}
          </div>
          <div>
            {isLoading ? (
              <p className="text-center my-5 text-xl">Loading ...</p>
            ) : (
              <Results
                key={listitem}
                provinces={Provinces}
                divisions={Divisions}
                districts={Districts}
                provinceCovered={provinceCovered}
                divisonCovered={divisonCovered}
                districtCovered={districtCovered}
                stores={Stores}
                onChildClick={setListitem}
                dealer={""}
              />
            )}
          </div>
        </div>
        <div className="overflow-y-scroll">
          <Maps
            dealer={"EMP"}
            mapdata={MapList}
            province={province}
            division={division}
          />
        </div>
      </Split>
    </div>
  );
};

export default VacantAreas;
